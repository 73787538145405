.experience-section {
  background-color: var(--bg-primary);
  border-radius: 24px;
  margin: 2rem 0;
}

.experience-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 5rem 1.5rem;
}

.timeline {
  position: relative;
  margin-top: 3rem;
}

.timeline::before {
  content: "";
  position: absolute;
  left: 2px;
  top: 0;
  height: 100%;
  width: 2px;
  background: linear-gradient(
    to bottom,
    var(--accent-primary) 0%,
    var(--accent-secondary) 100%
  );
}

.timeline-item {
  position: relative;
  margin-left: 2rem;
  padding: 2rem;
  background: var(--bg-secondary);
  border-radius: 16px;
  margin-bottom: 2rem;
  box-shadow: var(--shadow);
}

.timeline-item::before {
  content: "";
  position: absolute;
  left: -2.5rem;
  top: 2.5rem;
  width: 1rem;
  height: 1rem;
  background: var(--accent-primary);
  border-radius: 50%;
  border: 3px solid var(--bg-primary);
}

.job-header {
  margin-bottom: 1rem;
}

.job-header h3 {
  font-size: 1.5rem;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
}

.company {
  font-size: 1.1rem;
  color: var(--accent-primary);
  font-weight: 500;
}

.job-meta {
  display: flex;
  gap: 2rem;
  color: var(--text-secondary);
  font-size: 0.9rem;
  margin-bottom: 1.5rem;
}

.responsibilities {
  list-style: none;
  padding: 0;
  margin-bottom: 1.5rem;
}

.responsibilities li {
  position: relative;
  padding-left: 1.5rem;
  color: var(--text-secondary);
  margin-bottom: 1rem;
  line-height: 1.6;
}

.responsibilities li::before {
  content: "▹";
  position: absolute;
  left: 0;
  color: var(--accent-primary);
}

.tech-stack {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
}

.tech-tag {
  background: var(--bg-primary);
  color: var(--accent-primary);
  padding: 0.4rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
}

@media (max-width: 768px) {
  .experience-section {
    margin: 1rem 0;
    border-radius: 16px;
  }

  .timeline::before {
    left: 0;
  }

  .timeline-item {
    margin-left: 1.5rem;
  }

  .timeline-item::before {
    left: -2rem;
    top: 2.5rem;
  }

  .job-meta {
    flex-direction: column;
    gap: 0.5rem;
  }
}

/* Add these CSS variables to your root/body styles */
:root {
  --border-color: rgba(0, 0, 0, 0.1);
}

.dark {
  --border-color: rgba(255, 255, 255, 0.1);
}
