.hero {
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.hero-content {
  width: 1200px;
  position: relative;
  z-index: 1;
  margin: auto;
  padding: 0 1.5rem;
}

.hero-title {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.greeting {
  font-size: clamp(1.5rem, 5vw, 2rem);
  color: var(--accent-primary);
}

.role {
  font-size: clamp(2.5rem, 8vw, 4.5rem);
  font-weight: 800;
  line-height: 1.1;
  padding-bottom: 0.1em;
  background: linear-gradient(
    to right,
    var(--text-primary),
    var(--text-secondary)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-description {
  font-size: clamp(1.1rem, 3vw, 1.25rem);
  color: var(--text-secondary);
  margin: 2rem 0 3rem;
  max-width: 600px;
}

.hero-cta {
  display: flex;
  gap: 1.5rem;
}

.cta-primary,
.cta-secondary {
  padding: 0.8rem 2rem;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
}

.cta-primary {
  background: var(--accent-primary);
  color: white;
}

.cta-primary:hover {
  background: var(--accent-secondary);
  transform: translateY(-2px);
}

.cta-secondary {
  border: 2px solid var(--accent-primary);
  color: var(--accent-primary);
}

.cta-secondary:hover {
  background: var(--accent-primary);
  color: white;
  transform: translateY(-2px);
}

.hero-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.gradient-blob {
  position: absolute;
  top: -50%;
  right: -20%;
  width: 80vw;
  height: 80vw;
  background: radial-gradient(
    circle,
    var(--accent-secondary) 0%,
    rgba(9, 132, 227, 0.1) 50%,
    rgba(9, 132, 227, 0) 70%
  );
  opacity: 0.1;
  filter: blur(60px);
  animation: float 10s ease-in-out infinite;
}

@keyframes float {
  0%,
  100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-5%, 5%);
  }
}

@media (max-width: 768px) {
  .hero-cta {
    flex-direction: column;
  }

  .cta-primary,
  .cta-secondary {
    text-align: center;
  }
}

/* xs */
@media (min-width: 475px) {
}

/* sm */
@media (min-width: 640px) {
}

/* md */
@media (min-width: 768px) {
  .hero {
    margin-top: 0;
    height: 100vh;
    h1 {
      h1 {
        font-size: 5rem;
      }
    }
  }
  .socials {
    flex-direction: row;
  }
}

/* lg */
@media (min-width: 1280px) {
}

/* xl */
@media (min-width: 1536px) {
}
