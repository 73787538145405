.contact {
  padding: 3rem 1rem;
  max-width: 90%;
  margin: 0 auto;
  text-align: center;
}
.contact a {
  text-decoration: none;
  color: #317873;
}

.contact a:hover {
  text-decoration: underline;
}

/* xs */
@media (min-width: 475px) {
}

/* sm */
@media (min-width: 640px) {
}

/* md */
@media (min-width: 768px) {
}

/* lg */
@media (min-width: 1280px) {
}

/* xl */
@media (min-width: 1536px) {
}

.contact h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--text-primary);
}

.contact p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--text-secondary);
}

.contact-section {
  background-color: var(--bg-primary);
  border-radius: 24px;
  margin: 2rem 0;
}

.contact-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 5rem 1.5rem;
}

.contact-description {
  max-width: 600px;
  color: var(--text-secondary);
  font-size: 1.1rem;
  line-height: 1.7;
}

.contact-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  margin-top: 3rem;
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: left;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--text-secondary);
}

.info-item svg {
  width: 1.5rem;
  height: 1.5rem;
  color: var(--accent-primary);
  flex-shrink: 0;
}

.info-item a {
  color: var(--text-primary);
  text-decoration: none;
  transition: color 0.3s;
}

.info-item a:hover {
  color: var(--accent-primary);
}

.social-links {
  display: flex;
  gap: 1.5rem;
}

.social-link {
  color: var(--text-primary);
  transition: color 0.3s;
}

.social-link:hover {
  color: var(--accent-primary);
}

.social-link svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: currentColor;
}

/* Form Styles */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background: var(--bg-secondary);
  padding: 2rem;
  border-radius: 16px;
  box-shadow: var(--shadow);
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: left;
}

.form-group input,
.form-group textarea {
  padding: 0.8rem 1rem;
  border: 2px solid transparent;
  border-radius: 8px;
  background: var(--bg-primary);
  color: var(--text-primary);
  font-size: 1rem;
  transition: all 0.3s;
  resize: vertical;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: var(--accent-primary);
  box-shadow: 0 0 0 3px rgba(9, 132, 227, 0.1);
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: var(--text-secondary);
}

button[type="submit"] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 8px;
  background: var(--accent-primary);
  color: white;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;
}

button[type="submit"]:hover {
  background: var(--accent-secondary);
  transform: translateY(-2px);
}

button[type="submit"] svg {
  width: 1.2rem;
  height: 1.2rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-section {
    margin: 1rem 0;
    border-radius: 16px;
  }

  .contact-content {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .contact-info {
    order: 2;
  }

  .contact-form {
    padding: 1.5rem;
  }
}

@media (max-width: 480px) {
  .info-item {
    flex-direction: column;
    text-align: center;
    gap: 0.5rem;
  }

  .social-links {
    margin-top: 2rem;
    justify-content: center;
  }
}

.form-status {
  margin-top: 1rem;
  padding: 0.8rem;
  border-radius: 8px;
  text-align: center;
}

.form-status.success {
  background: rgba(72, 187, 120, 0.1);
  color: #48bb78;
}

.form-status.error {
  background: rgba(245, 101, 101, 0.1);
  color: #f56565;
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
