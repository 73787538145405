:root {
  /* Light theme */
  --bg-primary: #ffffff;
  --bg-secondary: #f8f9fa;
  --text-primary: #2d3436;
  --text-secondary: #636e72;
  --accent-primary: #0984e3;
  --accent-secondary: #74b9ff;
  --border: rgba(0, 0, 0, 0.1);
  --shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.dark {
  /* Dark theme */
  --bg-primary: #1a1a1a;
  --bg-secondary: #1e1e1e;
  --text-primary: #ffffff;
  --text-secondary: #b2bec3;
  --accent-primary: #74b9ff;
  --accent-secondary: #0984e3;
  --border: rgba(255, 255, 255, 0.1);
  --shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.3);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--bg-primary);
  color: var(--text-primary);
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, sans-serif;
  line-height: 1.5;
  transition: background-color 0.3s, color 0.3s;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 10rem;
  padding: 0 1.5rem;
}

section {
  padding: 1rem 0;
  scroll-margin-top: 5rem;
  border-radius: 24px;
  margin: 4rem 0;
}

.section-title {
  font-size: clamp(2rem, 5vw, 3rem);
  font-weight: 700;
  margin-bottom: 2rem;
  text-align: left;
  background: linear-gradient(
    to right,
    var(--accent-primary),
    var(--accent-secondary)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-subtitle {
  font-size: clamp(1rem, 3vw, 1.25rem);
  color: var(--text-secondary);
  margin-bottom: 3rem;
  text-align: left;
}

/* Keep hero section centered */
.hero .section-title,
.hero .section-subtitle {
  text-align: center;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-out forwards;
}

/* Responsive utilities */
@media (max-width: 768px) {
  .main-content {
    padding: 0 1rem;
  }

  section {
    padding: 3rem 0;
    margin: 2rem 0;
    border-radius: 16px;
  }

  .about-container,
  .experience-container,
  .projects-container,
  .contact-container {
    padding: 2rem 1rem;
  }
}

img,
svg {
  display: block;
  max-width: 100%;
}

button {
  display: inline-block;
  background: none;
  cursor: pointer;
  border: none;
  padding: 0;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
}

.sectionsContainer {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

/* xs */
@media (min-width: 385px) {
  .container {
    max-width: 375px;
  }
}

/* sm */
@media (min-width: 435px) {
  .container {
    max-width: 425px;
  }
}

/* md */
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

/* lg */
@media (min-width: 1034px) {
  .container {
    max-width: 1024px;
  }
  .sectionsContainer {
    gap: 5rem;
  }
}

/* xl */
@media (min-width: 1450px) {
  .container {
    max-width: 1440px;
  }
}

/* 2xl */
@media (min-width: 2570px) {
  .container {
    max-width: 2560px;
  }
}

/* Update section container classes */
.about-container,
.experience-container,
.projects-container,
.contact-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 1.5rem;
  border-radius: 24px;
}

/* Section-specific background colors */
.about-section,
.projects-section {
  background-color: var(--bg-secondary);
}

.experience-section,
.contact-section {
  background-color: var(--bg-primary);
}

/* Consistent text styles */
p {
  color: var(--text-secondary);
  line-height: 1.7;
  font-size: 1.1rem;
}
