.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: transparent;
  transition: all 0.3s ease;
  padding: 1rem 0;
}

.header.scrolled {
  background: var(--bg-primary);
  box-shadow: var(--shadow);
  padding: 0.8rem 0;
  backdrop-filter: blur(10px);
}

.nav-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--accent-primary);
  background: none;
  border: none;
  cursor: pointer;
  letter-spacing: -1px;
  padding: 0;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-left: auto;
}

.nav-link {
  color: var(--text-primary);
  background: none;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: color 0.3s;
  padding: 0;
}

.nav-link:hover {
  color: var(--accent-primary);
}

.theme-toggle,
.menu-toggle {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  justify-content: center;
}

.theme-toggle svg,
.menu-toggle svg {
  width: 1.2rem;
  height: 1.2rem;
}

.menu-toggle {
  display: none;
}

.header-buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: 2rem;
}

.resume-link {
  color: var(--text-primary);
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s;
  padding: 0;
}

.resume-link:hover {
  color: var(--accent-primary);
}

@media (max-width: 768px) {
  .nav-links {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    background: var(--bg-primary);
    padding: 2rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    box-shadow: var(--shadow);
    border-top: 1px solid var(--border);
    transform-origin: top;
    transform: scaleY(0);
  }

  .nav-links.open {
    transform: scaleY(1);
    opacity: 1;
    visibility: visible;
  }

  .nav-links a {
    display: block;
    width: 100%;
    font-size: 1.2rem;
  }

  .menu-toggle {
    display: block;
  }

  .resume-link {
    font-size: 1.2rem;
    width: 100%;
    text-align: left;
  }
}
